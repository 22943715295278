<template>
  <div class="theme-wrapper">
    <div class="slogan-layout">
      <div class="slogan-layout__slogan">
        <img
          class="h-[38px]"
          :src="SloganImgOhbot"
          :srcset="`${SloganImgOhbot2x} 1x, ${SloganImgOhbot4x} 2x`"
          alt="slogan image"
        >
        <template v-if="orgLogo !== ''">
          <div class="slogan-layout__cross" />
          <img class="slogan-layout__image" :src="imgSource(orgLogo)" alt="organization logo">
        </template>
      </div>
      <img
        class="slogan-layout__crm"
        :src="CrmImage"
        :srcset="`${CrmImage2x} 1x, ${CrmImage4x} 2x`"
        alt="CRM slogan image"
      >
      <div class="slogan-layout__text">
        <p>從顧客管理創造永續營收</p>
        <p>用 Social CRM 打造0距離的顧客體驗</p>
      </div>
    </div>
    <LoginForm
      :app-theme="appTheme"
      @change-selected-org="changeSelectedOrg"
      @showTerms="$emit('showTerms')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginForm from './LoginForm.vue'
import SloganImgOhbot from '@/assets/login/ohbot/slogan-ohbot.png'
import SloganImgOhbot2x from '@/assets/login/ohbot/slogan-ohbot-2x.png'
import SloganImgOhbot4x from '@/assets/login/ohbot/slogan-ohbot-4x.png'
import CrmImage from '@/assets/login/ohbot/crm-image.png'
import CrmImage2x from '@/assets/login/ohbot/crm-image-2x.png'
import CrmImage4x from '@/assets/login/ohbot/crm-image-4x.png'
import { imgSrc } from '@/utils/helper'

export default {
  name: 'LayoutOhbot',

  components: {
    LoginForm,
  },

  data: () => ({
    SloganImgOhbot,
    SloganImgOhbot2x,
    SloganImgOhbot4x,
    CrmImage,
    CrmImage2x,
    CrmImage4x,
    imgSize: 90,
    selectedOrg: '',
  }),

  computed: {
    ...mapGetters([
      'appTheme',
    ]),

    orgLogo () {
      return this.selectedOrg?.AuthServiceConfig?.Logo?.filename ? this.selectedOrg.AuthServiceConfig.Logo : ''
    },
  },

  methods: {
    imgSource (image) {
      return imgSrc(this.imgSize, image)
    },

    changeSelectedOrg (selectedOrg) {
      this.selectedOrg = selectedOrg
    },
  },
}
</script>

<style lang="scss" scoped>
.theme-wrapper {
  @apply w-full h-full flex flex-nowrap justify-around;
}

.slogan-layout {
  @apply flex flex-col justify-center w-1/2 z-[2] py-0 px-[64px];

  &__slogan {
    @apply h-[40px] flex items-center justify-center;
  }

  &__crm {
    @apply mt-[75px] max-h-[380px] object-contain;
  }

  &__cross {
    @apply relative w-[20px] h-[20px] mx-[24px];

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: calc(50% - 2px);
      left: 0;
      width: 20px;
      height: 4px;
      border-radius: 2px;
      background-color: #000000;
    }

    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }

  &__image {
    @apply max-h-[90px] object-contain;
  }

  &__text {
    @apply mt-[60px] text-[19px] text-[#0075FF] text-center leading-[32px];
  }
}

@media screen and (max-width: 1023px) {
  .slogan-layout {
    @apply z-0 content-around;

    &__slogan {
      @apply fixed top-[10%] left-[10%];
    }

    &__crm {
      @apply fixed top-[50%] scale-125 translate-x-[-42%] translate-y-[-70%];
    }

    &__text {
      display: none;
    }
  }
}
</style>
