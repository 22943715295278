<template>
  <div class="form-layout">
    <slot name="top" />
    <div class="login-form">
      <el-form ref="form" class="login-form-container" label-position="top" :model="authFormData" :rules="formRules">
        <h1 v-if="['righttime', 'wishmobile', 'xuanien'].includes(appTheme)" class="login-form__login">LOGIN</h1>
        <h1 v-else class="login-form__login view-title">登入</h1>
        <el-form-item prop="orgCode" label="集團">
          <OrgSearch data-cy="org-select" class="form__input__org" :model.sync="queryOrg" :disabled="!!subdomainCode" @change="changeOrg" />
          <img v-if="['righttime', 'wishmobile', 'xuanien'].includes(appTheme)" class="prefix__icon" :src="iconOrg" alt="organization icon">
        </el-form-item>
        <el-form-item prop="email" label="電子信箱">
          <el-input v-model="authFormData.email" data-cy="email-input" class="form__input__email" placeholder="請輸入電子信箱" @keyup.enter.native="handleLogin" />
          <img v-if="['righttime', 'wishmobile', 'xuanien'].includes(appTheme)" class="prefix__icon" :src="iconEmail" alt="email icon">
        </el-form-item>
        <el-form-item class="login-form__password" prop="password" label="密碼">
          <el-input
            v-model="authFormData.password"
            data-cy="password-input"
            class="form__input__password"
            show-password
            placeholder="請輸入密碼"
            @keyup.enter.native="handleLogin"
          />
          <img v-if="['righttime', 'wishmobile', 'xuanien'].includes(appTheme)" class="prefix__icon" :src="iconPassword" alt="password icon">
        </el-form-item>
        <el-form-item prop="agreeTerms" class="login-form__agreecheck">
          <el-checkbox :value="authFormData.agreeTerms" @change="handleChangeTerms">
            勾選代表你已詳閱並同意
            <span class="agree-terms" @click.exact="handleClickTerms">《系統個人資料保護政策》。</span>
          </el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button data-cy="login-btn" type="primary" @click="handleLogin">登入</el-button>
        </el-form-item>
      </el-form>
    </div>
    <p class="copyright">
      © {{ year }} {{ copyRightSite }} <span class="version">目前使用版本v{{ version }}</span>
    </p>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import ls from '@/utils/localstorage'
import formUtils from '@/utils/form'
import OrgSearch from '@/components/Search/OrgSearch.vue'
import { GetOrgsByCode } from '@/api/auth'
import { noEmptyRules, changeEventRules } from '@/validation'
import iconOrg from '@/assets/login/right-time/icon-org.svg'
import iconEmail from '@/assets/login/right-time/icon-email.svg'
import iconPassword from '@/assets/login/right-time/icon-password.svg'
import { getAppVersion } from '@/utils/app'

export default {
  name: 'LoginForm',

  components: {
    OrgSearch,
  },
  props: {
    appTheme: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    iconOrg,
    iconEmail,
    iconPassword,
    subdomainCode: null,
    formRules: {
      email: noEmptyRules('請輸入正確電子信箱'),
      password: noEmptyRules('請輸入密碼'),
      orgCode: changeEventRules('請選擇集團'),
      agreeTerms: {
        message: '請閱讀並勾選同意',
        trigger: 'change',
        validator: (rule, value, callback) => {
          if (value) return callback()
          return callback(new Error('請勾選'))
        },
      },
    },
    authFormData: {
      orgCode: '',
      email: '',
      password: '',
      agreeTerms: false,
    },
    orgList: [],
    queryOrg: '',
    selectedOrg: null,
    isClickTerms: false,
  }),

  computed: {
    version () {
      return getAppVersion()
    },

    year () {
      return dayjs().year()
    },

    copyRightSite () {
      let site = 'ohbottech.com'
      if (this.appTheme === 'wishmobile') {
        site = 'wishmobile.com'
      }
      if (this.appTheme === 'xuanien') {
        site = 'Xuanen.com'
      }
      return site
    },
  },

  async mounted () {
    // console.log('🚧', 'login debug mode')
    // const code = 'devorg'
    if (await this.checkSubDomain()) {
      return
    }
    const code = this.$route.params.orgCode

    if (code) {
      this.authFormData.orgCode = code
      const org = this.orgList.find((item) => item.code === code)
      this.queryOrg = org?.name || ''
    }
  },

  methods: {
    // -- 選擇集團
    async changeOrg () {
      this.authFormData.orgCode = this.queryOrg.code
      await this.getOrgsInfoByCode()
    },

    async getOrgsInfoByCode () {
      try {
        this.selectedOrg = await GetOrgsByCode({ code: this.authFormData.orgCode })
        this.queryOrg = this.selectedOrg.name
        this.$emit('change-selected-org', this.selectedOrg)
      } catch (error) {
        console.log(error)
      }
    },

    // -- 判斷集團 subdomain
    async checkSubDomain () {
      const row = location.host.split('.')
      if (row.length === 4) {
        this.subdomainCode = row[0]
        this.authFormData.orgCode = this.subdomainCode
        await this.getOrgsInfoByCode()
        if (!this.queryOrg) return this.$router.push('/not-found')

        return true
      }
      return false
    },

    //= > 普通登入
    async handleLogin () {
      try {
        if (!(await formUtils.checkForm(this.$refs.form))) return

        await this.$store.dispatch('auth/login', { ...this.authFormData })
        this.$message.success('登入成功!')
        if (ls.getNeedTransfer()) {
          const transferPath = ls.getNeedTransfer()

          ls.removeNeedTransfer()
          this.$router.push(transferPath)
        } else this.$router.push('/').catch(() => {})
      } catch (error) {
        this.$message.error({
          message: error || error.message,
        })
      }
    },
    handleClickTerms (event) {
      this.$emit('showTerms')
      this.isClickTerms = true
    },
    handleChangeTerms () {
      if (this.isClickTerms) {
        this.isClickTerms = false
        return
      }
      this.authFormData.agreeTerms = !this.authFormData.agreeTerms
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  @apply mb-[16px] text-white text-[24px] font-medium;
}

.form-layout {
  @apply z-[1] relative flex flex-col justify-center items-center w-6/12;

  .beauty & {
    @apply mr-[120px];
  }

  .ohbot & {
    background: linear-gradient(151.14deg, #0075ff -5.26%, #014eff 83.76%);
  }

  .login-form {
    @apply mb-[16px] pt-[81px] px-[28px] pb-[18px] w-[456px] bg-white rounded-[4px];
    box-shadow: 0 1px 30px rgba(1, 10, 38, 0.1);

    .ohbot & {
      border-radius: 50px 4px;
    }

    .righttime &, .wishmobile &, .xuanien & {
      @apply w-[600px] shadow-none;
    }

    &__login {
      @apply relative text-gray-100;

      &::after {
        .ohbot & {
          content: '';
          @apply absolute top-[calc(50%-9px)] left-[56px] w-[20px] h-[18px];
          background-image: url('../../../assets/login/ohbot/login-icon.png');
        }
      }

      .righttime &, .wishmobile &, .xuanien & {
        @apply mb-[68px] text-center text-primary-100 text-[38px] font-bold tracking-[4px];
      }
    }

  }
  .login-form__agreecheck {
    .agree-terms {
      @apply inline whitespace-normal text-primary-100 cursor-pointer;
    }
    ::v-deep {
      .el-checkbox {
        @apply flex;
      }
      .el-checkbox__label {
        @apply text-info cursor-default;
      }
    }
  }
  .copyright {
    .beauty & {
      @apply text-[#5E5E5E];
    }

    .ohbot & {
      @apply text-[#FFFFFF];
    }

    .righttime &, .wishmobile &, .xuanien &{
      @apply absolute bottom-[68px];
      @apply text-primary-50;
    }

    .version {
      @apply ml-[20px];
    }
  }

  ::v-deep .el-form-item__content {
    .righttime &, .wishmobile &, .xuanien &  {
      @apply flex justify-center;
    }
  }

  ::v-deep .el-button {
    @apply w-[140px] h-[48px] float-right;

    .ohbot & {
      @apply bg-[#0075FF] border-none rounded-[4px];
      box-shadow: 0px 1px 9px rgba(1, 10, 38, 0.1);
    }

    .righttime &, .wishmobile &, .xuanien &  {
      @apply mt-[52px] w-full max-w-[400px] h-[60px] rounded-[34px] text-[24px];
    }
  }

  ::v-deep .el-form-item__label {
    .righttime &, .wishmobile &, .xuanien &  {
      @apply relative text-primary-100;
    }

    &::before {
      .righttime &, .wishmobile &, .xuanien &  {
        @apply absolute right-[-16px];
      }
    }
  }

  ::v-deep .el-input__inner {
    .righttime &, .wishmobile &, .xuanien &  {
      @apply pb-[4px] pl-[48px] text-[20px] border-none rounded-none;
      border-bottom: 1px solid var(--primary-100);
    }
  }

  ::v-deep .el-input__prefix {
    .righttime &, .wishmobile &, .xuanien &  {
      @apply hidden;
    }
  }
}

.form__input__org,
.form__input__email,
.form__input__password {
  @apply w-full;
}

.prefix__icon {
  @apply absolute bottom-[8px] left-0 object-contain;
}

@media screen and (max-width: 1023px) {
  .form-layout {
    .ohbot & {
      @apply fixed top-2/4 right-[80px] translate-y-[-50%] bg-none;
    }

    .righttime & {
      @apply w-full;
      background: linear-gradient(172.5deg, #8fd6bf -1.98%, #5fc4a4 101.46%);

      .login-form {
        @apply pt-[40px] pb-[180px] px-[60px] rounded-[20px];
      }
    }
    .wishmobile & {
      @apply w-full;
      background: linear-gradient(172.5deg, #1D60AE -1.98%, #1D60AE 101.46%);

      .login-form {
        @apply pt-[40px] pb-[180px] px-[60px] rounded-[20px];
      }
    }
  }

  .form-layout > .copyright {
    .ohbot & {
      @apply text-[#0075FF];
    }
  }
}

@media screen and (max-width: 1200px) {
  .form-layout {
    .righttime & {
      @apply justify-start w-full min-h-[1194px];
      background: linear-gradient(172.5deg, #8fd6bf -1.98%, #5fc4a4 101.46%);

      .login-form {
        @apply mb-[60px] pt-[40px] pb-[180px] px-[60px] rounded-[20px];
        box-shadow: 0 21px 30px rgba(1, 10, 38, 0.1);
      }
    }

    .wishmobile & {
      @apply justify-start w-full min-h-[1194px];
      background: linear-gradient(172.5deg, #1D60AE -1.98%, #1D60AE 101.46%);

      .login-form {
        @apply mb-[60px] pt-[40px] pb-[180px] px-[60px] rounded-[20px];
        box-shadow: 0 21px 30px rgba(1, 10, 38, 0.1);
      }
    }

    .copyright {
      .righttime &, .wishmobile &  {
        @apply absolute bottom-[16px] left-[24px];
        @apply text-[14px] leading-[16px];
      }
    }
  }
}
</style>
