<template>
  <div class="login" :class="activeAppTheme">
    <LayoutBeauty v-if="activeAppTheme === 'beauty'" @showTerms="showTerms = true" />
    <LayoutOhbot v-if="activeAppTheme === 'ohbot'" @showTerms="showTerms = true" />
    <LayoutRighttime v-if="activeAppTheme === 'righttime'" @showTerms="showTerms = true" />
    <LayoutWishMobile v-if="activeAppTheme === 'wishmobile'" @showTerms="showTerms = true" />
    <LayoutWishMobile v-if="activeAppTheme === 'salesOhbot'" @showTerms="showTerms = true" />
    <LayoutXuanien v-if="activeAppTheme === 'xuanien'" @showTerms="showTerms = true" />
    <LoginTerms :show.sync="showTerms" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LayoutBeauty from './components/LayoutBeauty.vue'
import LayoutOhbot from './components/LayoutOhbot.vue'
import LayoutRighttime from './components/LayoutRighttime.vue'
import LayoutWishMobile from './components/LayoutWishMobile.vue'
import LayoutXuanien from './components/LayoutXuanien.vue'
import LoginTerms from './components/LoginTerms.vue'

export default {
  name: 'Login',

  components: {
    LayoutBeauty,
    LayoutOhbot,
    LayoutRighttime,
    LayoutWishMobile,
    LoginTerms,
    LayoutXuanien,
  },
  data () {
    return {
      showTerms: false,
    }
  },
  computed: {
    ...mapGetters([
      'appTheme',
    ]),

    activeAppTheme () {
      return this.appTheme
    },
  },
}
</script>

<style scoped lang="scss">
.login {
  @apply w-full h-full;
}
</style>
