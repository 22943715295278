<template>
  <div class="terms-raw">
    <p>客戶關係管理平台（以下簡稱本服務）是本公司創建的客戶關係管理雲端服務。若您申請客戶關係管理平台帳戶（以下簡稱使用帳戶，包含付費帳戶或免費帳戶）並使用相應服務，您必須首先同意以下個人資料保護協議。</p>

    <p>ㄧ、遵守個人資料保護法</p>
    <p>帳戶使用本服務，將對您的顧客的個人資料之蒐集、處理及利用，需避免人格權受侵害，並促進個人資料之合理利用，故須遵守本法。用詞定義如下：</p>

    <ol>
      <li>
        個人資料：指自然人之姓名、出生年月日、國民身分證統一編號、護照號碼、特徵、指紋、婚姻、家庭、教育、職業、病歷、醫療、基因、性生活、健康檢查、犯罪前科、聯絡方式、財務情況、社會活動及其他得以直接或間接方式識別該個人之資料。
      </li>
      <li>個人資料檔案：指依系統建立而得以自動化機器或其他非自動化方式檢索、整理之個人資料之集合。</li>
      <li>蒐集：指以任何方式取得個人資料。</li>
      <li>處理：指為建立或利用個人資料檔案所為資料之紀錄、輸入、儲存、編輯、更正、複製、檢索、刪除、輸出、連結或內部傳送。</li>
      <li>利用：指將蒐集之個人資料為處理以外之使用。</li>
      <li>國際傳輸：指將個人資料作跨國（境）之處理或利用。</li>
      <li>公務機關：指依法行使公權力之中央或地方機關或行政法人。</li>
      <li>非公務機關：指前款以外之自然人、法人或其他團體。</li>
      <li>當事人：指個人資料之本人。</li>
    </ol>

    <p>二、遵守特種個人資料之保護</p>
    <p>個人資料保護法第六條（特種個人資料之保護）內容為：有關病歷、醫療、基因、性生活、健康檢查及犯罪前科之個人資料，不得蒐集、處理或利用。</p>

    <p>但有下列情形之一者，不在此限：</p>
    <ol>
      <li>法律明文規定。</li>
      <li>公務機關執行法定職務或非公務機關履行法定義務必要範圍內，且事前或事後有適當安全維護措施。</li>
      <li>當事人自行公開或其他已合法公開之個人資料。</li>
      <li>公務機關或學術研究機構基於醫療、衛生或犯罪預防之目的，為統計或學術研究而有必要，且資料經過提供者處理後或經蒐集者依其揭露方式無從識別特定之當事人。</li>
      <li>為協助公務機關執行法定職務或非公務機關履行法定義務必要範圍內，且事前或事後有適當安全維護措施。</li>
      <li>經當事人書面同意。但逾越特定目的之必要範圍或其他法律另有限制不得僅依當事人書面同意蒐集、處理或利用，或其同意違反其意願者，不在此限。</li>
    </ol>

    <p>依前項規定蒐集、處理或利用個人資料，準用第八條、第九條規定；其中前項第六款之書面同意，準用第七條第一項、第二項及第四項規定，並以書面為之。</p>

    <p>三、本服務對所收集資訊的聲明</p>
    <ol>
      <li>依個人資料保護法之規定，個人資料及特種個人資料之搜集、處理、利用均應經由個人資料當事人之同意，特此聲明。</li>
      <li>
        只有本服務的帳戶才可以進行發布商業資訊，查看其他帳戶聯繫方式等活動。本服務有權審核發布或刪除帳戶提交的資訊。所有的帳戶對其發布資訊的準確性、完整性、即時性、合法性都獨立承擔所有責任，本服務會盡可能檢查帳戶提交的資訊，但並不能完全保證資訊的準確性和合法性，同時也不承擔由此產生的任何法律責任。本服務在任何情況下均不就因本網站、本網站的服務或本協議而產生或與之有關的利潤損失或任何特別、間接或後果性的損害（無論以何種方式產生，包括疏忽）承擔任何責任。
      </li>
      <li>
        使用本服務之客戶存放的資料內容：除本服務權供客戶使用的資料外，本服務不會主張客戶在服務中所提供內容的所有權。客戶的資料內容仍屬於客戶所有，對於客戶與個人資料提供人於本服務中提供的內容，本服務並不負資料完整性、正確性之保證。
      </li>
      <li>
        使用本服務之客戶，經個人資料當事人之同意，得依個人資料保護法進行個人資料之蒐集、處理及利用，但若客戶另外自行蒐集、處理、利用個人資料時，應負單獨之保管、保全責任，若未經個人資料當事人同意而有故意或過失洩漏個人資料時，本服務不負相關責任。
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: 'TermsRaw',
}
</script>

<style lang="scss" scoped>
.terms-raw {
  ol,li {
    list-style: decimal;
    display: list-item;
  }
  li {
    @apply ml-5 mb-2;
  }
  p {
    @apply mb-2;
  }
}
</style>
