<template>
  <div class="theme-wrapper">
    <LoginForm
      :app-theme="appTheme"
      @showTerms="$emit('showTerms')"
    >
      <template #top>
        <img
          class="slogan-layout__form__slogan"
          :src="SloganImgWishMobile"
          :srcset="`${SloganImgWishMobile2x} 1x, ${SloganImgWishMobile4x} 2x`"
          alt="slogan image"
        >
        <img class="slogan-layout__background-small" :src="WishMobileImage2x" alt="background small image">
      </template>
    </LoginForm>
    <div class="slogan-layout">
      <img
        class="slogan-layout__slogan"
        :src="SloganImgWishMobile"
        :srcset="`${SloganImgWishMobile2x} 1x, ${SloganImgWishMobile4x} 2x`"
        alt="slogan image"
      >
      <img class="slogan-layout__background" :src="WishMobileImage2x" alt="background image">
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginForm from './LoginForm.vue'
import SloganImgWishMobile from '@/assets/login/wish-mobile/slogan-wishmobile.png'
import SloganImgWishMobile2x from '@/assets/login/wish-mobile/slogan-wishmobile-2x.png'
import SloganImgWishMobile4x from '@/assets/login/wish-mobile/slogan-wishmobile-4x.png'
import WishMobileImage from '@/assets/login/wish-mobile/wishmobile-image.png'
import WishMobileImage2x from '@/assets/login/wish-mobile/wishmobile-image-2x.png'

export default {
  name: 'LayoutWishMobile',

  components: {
    LoginForm,
  },

  data: () => ({
    SloganImgWishMobile,
    SloganImgWishMobile2x,
    SloganImgWishMobile4x,
    WishMobileImage,
    WishMobileImage2x,
  }),

  computed: {
    ...mapGetters([
      'appTheme',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.theme-wrapper {
  @apply w-full h-full flex flex-nowrap justify-around;
}

.slogan-layout {
  @apply flex flex-col justify-center items-center w-1/2;
  background: linear-gradient(172.5deg, #1D60AE -1.98%, #1D60AE 101.46%);

  &__form__slogan {
    @apply hidden;
  }

  &__slogan {
    @apply h-[76px] my-[83px] object-contain;
  }

  &__background {
    @apply max-w-[575px];
  }

  &__background-small {
    @apply hidden;
  }
}

@media screen and (max-width: 1200px) {
  .theme-wrapper {
    background: linear-gradient(172.5deg, #8FD6BF -1.98%, #5FC4A4 101.46%);
  }

  .slogan-layout {
    @apply z-[1] fixed right-0 w-0;

    &__form__slogan {
      @apply z-[1] box-content block h-[76px] object-contain pt-[68px] pb-[72px];
    }

    &__slogan {
      @apply hidden;
    }

    &__background {
      @apply hidden;
    }

    &__background-small {
      @apply z-[1] block absolute right-[6vw] bottom-[30px] object-contain w-[512px] pointer-events-none;
    }
  }
}
</style>
