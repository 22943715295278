<template>
  <div class="theme-wrapper" :class="appTheme">
    <div class="slogan-layout">
      <img class="slogan-layout__slogan" :src="SloganImgBeauty">
    </div>
    <LoginForm
      :app-theme="appTheme"
      @showTerms="$emit('showTerms')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginForm from './LoginForm.vue'
import SloganImgBeauty from '@/assets/login/beauty/slogan-beauty.png'

export default {
  name: 'LayoutBeauty',

  components: {
    LoginForm,
  },

  data: () => ({
    SloganImgBeauty,
  }),

  computed: {
    ...mapGetters([
      'appTheme',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.theme-wrapper {
  @apply w-full h-full flex flex-nowrap justify-around;
  background-image: url("../../../assets/login/beauty/background-beauty.png");
  background-position: right center;
  background-repeat: no-repeat;
}

.slogan-layout {
  @apply flex flex-col justify-center w-6/12 z-[2] mt-[177px] mr-auto mb-auto ml-[86px];

  &__slogan {
    @apply max-w-[114px];
  }
}

@media screen and (max-width: 1023px) {
  .theme-wrapper {
    background-position: 86px center;
  }

  .slogan-layout {
    @apply z-0 content-around;

    &__slogan {
      @apply fixed top-[10%] left-[10%];
    }

  }
}

@media screen and (max-width: 1450px) {
  .theme-wrapper {
    background-position: 86px center;
  }
}
</style>
