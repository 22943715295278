<template>
  <div class="theme-wrapper">
    <LoginForm
      :app-theme="appTheme"
      @showTerms="$emit('showTerms')"
    >
      <template #top>
        <img
          class="xuanien-logo"
          style="margin-bottom: 40px"
          :src="xuanienLogo"
          alt="slogan image"
        >
        <img class="slogan-layout__background-small" :src="wallpaper" alt="background small image">
      </template>
    </LoginForm>
    <div class="slogan-layout">
      <!-- <img
        class="slogan-layout__slogan"
        :src="xuanienLogo"
        alt="slogan image"
      > -->
      <img class="slogan-layout__background px-[81.5px]" style="max-width: none; width: 100%" :src="wallpaper" alt="background image">
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginForm from './LoginForm.vue'
import xuanienLogo from '@/assets/login/xuanien/logo-xuanien-2x.png'
import wallpaper from '@/assets/login/xuanien/wallpaper-1x.png'
import wallpaper2x from '@/assets/login/xuanien/wallpaper-2x.png'
import wallpaper4x from '@/assets/login/xuanien/wallpaper-4x.png'

export default {
  name: 'LayoutRighttime',

  components: {
    LoginForm,
  },

  data: () => ({
    wallpaper,
    wallpaper2x,
    wallpaper4x,
    xuanienLogo,
  }),

  computed: {
    ...mapGetters([
      'appTheme',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.theme-wrapper {
  @apply w-full h-full flex flex-nowrap justify-around;
}

.slogan-layout {
  @apply flex flex-col justify-center items-center w-1/2 bg-primary-50;
  // background: linear-gradient(172.5deg, #8FD6BF -1.98%, #5FC4A4 101.46%);

  &__form__slogan {
    @apply hidden;
  }

  &__slogan {
    @apply h-[76px] object-contain;
  }

  &__background {
    @apply px-[72px] w-full;
    // max-w-[575px]
  }

  &__background-small {
    @apply hidden;
  }
}

.xuanien-logo {
  @apply hidden;
}

@media screen and (max-width: 1200px) {

  .xuanien-logo {
    @apply block;
  }
  .theme-wrapper {
    @apply bg-primary-50;
    // background: linear-gradient(172.5deg, #8FD6BF -1.98%, #5FC4A4 101.46%);
  }

  .slogan-layout {
    @apply z-[1] fixed right-0 w-0;

    &__form__slogan {
      @apply z-[1] box-content block h-[76px] object-contain pt-[68px] pb-[72px];
    }

    &__slogan {
      // @apply absolute top-0;
      // @apply hidden;
    }

    &__background {
      @apply hidden;
    }

    // &__background-small {
    //   @apply z-0 block right-[6vw] bottom-[30px] object-contain w-[512px] pointer-events-none;
    // }
  }
}
</style>
