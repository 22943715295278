<template>
  <div class="login-terms">
    <el-dialog
      :title="title"
      :visible.sync="observeShow"
      width="610px"
      :close-on-click-modal="false"
      :append-to-body="false"
      :modal-append-to-body="false"
      :show-close="false"
      top="10vh"
      @close="closeDialog"
    >
      <template #title>
        <h2>系統個人資料保護政策</h2>
      </template>
      <div class="terms-containers mb-5">
        <div class="terms-inner">
          <TermsRaw />
        </div>
      </div>
      <div class="text-center">
        <el-button type="primary" @click="closeDialog">確認</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TermsRaw from './TermsRaw.vue'

export default {
  name: 'LoginTerms',
  components: {
    TermsRaw,
  },
  props: {
    title: { type: String },
    show: { type: Boolean },
  },
  computed: {
    observeShow: {
      get () {
        return this.show
      },
      set (value) {
        this.$emit('update:show', value)
      },
    },
  },
  methods: {
    closeDialog () {
      this.observeShow = false
    },
  },
}
</script>

<style lang="scss" scoped>
.login-terms {
}
.terms-containers {
  @apply border-[1px] border-[#aeaeae] rounded-[10px] p-2.5;

  .terms-inner {
    @apply h-[560px] overflow-y-auto;
  }
}
</style>
